import { lazy } from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { connect } from 'react-redux';

import { ClientSuspense } from 'dibs-elements/exports/ClientSuspense';
import { pageTypeConstants as PAGE_TYPES } from '../constants/pageTypeConstants';
import { MIN_THIN_PAGE_COUNT } from '../constants/sbConstants';

import { BuySharedBreadcrumbs } from './BuySharedBreadcrumbs/BuySharedBreadcrumbs';
import { BuySharedHead } from './BuySharedHead/BuySharedHead';
import { BuySharedTitle } from './BuySharedTitle/BuySharedTitle';
import { BuySharedRelatedPages } from './BuySharedRelatedPages/BuySharedRelatedPages';
import { BuySharedResultContainer } from './BuySharedResultContainer/BuySharedResultContainer';
import { SharedPagination } from '../shared/SharedPagination/SharedPagination';
import { BuySharedDescription } from './BuySharedDescription/BuySharedDescription';
import { BuySharedNoInventoryAvailable } from './BuySharedNoInventoryAvailable/BuySharedNoInventoryAvailable';
import { SharedRecentSales } from '../shared/SharedRecentSales/SharedRecentSales';
import { BuySharedCMSFAQ } from './BuySharedCMSFAQ/BuySharedCMSFAQ';
import { SharedFAQ } from '../shared/SharedFAQ/SharedFAQ';
import { SharedRespLinksBlock } from '../shared/SharedRespLinksBlock/SharedRespLinksBlock';
import { SharedBottomModule } from '../shared/SharedBottomModule/SharedBottomModule';
import { SharedWordpressArticles } from '../shared/SharedWordpressArticles/SharedWordpressArticles';
import { SharedFollowTopButton } from '../shared/SharedFollowTopButton/SharedFollowTopButton';
import { SharedFollowSearchBanner } from '../shared/SharedFollowSearchBanner/SharedFollowSearchBanner';
import { BuyRespTopNav } from './BuyRespTopNav/BuyRespTopNav';
import { BuyRespRefineMenu } from './BuyRespRefineMenu/BuyRespRefineMenu';
import { BuySharedRefetchContainer } from './BuySharedRefetchContainer';
import { BuyRespCategoryTiles } from './BuyCategoryTiles/BuyRespCategoryTiles';
import { BuySharedPersonalizedSearchItems } from './BuySharedPersonalizedSearchItems/BuySharedPersonalizedSearchItems';

import styles from './BuySharedLayout.scss';
import { SharedSaveSearchContextProvider } from '../shared/SharedSaveSearchContext/SharedSaveSearchContext';

const SharedYMALLazyComponent = lazy(() =>
    import(/* webpackChunkName: "SharedYMAL" */ '../shared/SharedYMAL/SharedYMAL')
);

const BuyRespLayoutComponent = ({ viewer, relay, isClient, page, generatedUriRef }) => {
    const {
        buyPage,
        user = null,
        isWordpressArticlesEnabled,
        isNlgContentEnabled,
        isRelatedBuyPagesEnabled,
    } = viewer;
    const {
        internalBuyLinks,
        bottomModule,
        filters,
        displayMaxNumberOfPages,
        totalResults,
        appliedFilters,
        displayUriRef,
        meta,
    } = buyPage;

    const { buyPageAvailableItemCount } = meta || {};
    const searchTerm = appliedFilters?.find(f => f?.name === PAGE_TYPES.BUY)?.values?.[0]
        ?.displayName;

    // Available item count for redesign is increased from 4 to 30 (GROWTH-7399)
    // UPDATE: Keeping redesign for up to 30 available items forever (GROWTH-7528)
    const showBuyPageRedesign = buyPageAvailableItemCount <= MIN_THIN_PAGE_COUNT;

    return (
        <SharedSaveSearchContextProvider
            itemSearch={buyPage}
            user={user}
            searchTerm={searchTerm}
            uriRef={displayUriRef}
        >
            <>
                <BuySharedHead buyPage={buyPage} />
                <BuySharedRefetchContainer
                    viewer={viewer}
                    buyPage={buyPage}
                    refetch={relay.refetch}
                />
                <BuySharedBreadcrumbs buyPage={buyPage} />
                <div className={styles.headerLeftWrapper}>
                    <div className={styles.headerLeft}>
                        <BuySharedTitle buyPage={buyPage} />
                        {showBuyPageRedesign && (
                            <SharedFollowTopButton
                                itemSearch={buyPage}
                                user={user}
                                addLeadingSpace
                            />
                        )}
                    </div>
                </div>

                {isRelatedBuyPagesEnabled && <BuySharedRelatedPages buyPage={buyPage} />}

                <div className={styles.spacer} />

                <div className={styles.mainContent}>
                    {!showBuyPageRedesign && (
                        <BuyRespRefineMenu
                            viewer={viewer}
                            itemSearch={buyPage}
                            user={user}
                            filters={filters}
                            isClient={isClient}
                            uriRef={generatedUriRef}
                        />
                    )}
                    <div className={styles.container}>
                        {totalResults ? (
                            <>
                                {!showBuyPageRedesign && (
                                    <BuyRespTopNav buyPage={buyPage} user={user} />
                                )}

                                <BuySharedResultContainer
                                    viewer={viewer}
                                    buyPage={buyPage}
                                    user={user}
                                    showBuyPageRedesign={showBuyPageRedesign}
                                />
                                <SharedPagination
                                    currentPage={page}
                                    numberOfPages={displayMaxNumberOfPages}
                                    displayUriRef={displayUriRef}
                                    hideHref
                                />
                            </>
                        ) : (
                            <BuySharedNoInventoryAvailable />
                        )}

                        {showBuyPageRedesign && (
                            <>
                                <ClientSuspense fallback="">
                                    <SharedYMALLazyComponent
                                        user={user}
                                        viewer={viewer}
                                        itemSearch={buyPage}
                                        isClient={isClient}
                                    />
                                </ClientSuspense>
                                <BuySharedPersonalizedSearchItems
                                    viewer={viewer}
                                    user={user}
                                    itemSearch={buyPage}
                                    isClient={isClient}
                                />
                                <SharedRecentSales
                                    user={user}
                                    viewer={viewer}
                                    itemSearch={buyPage}
                                    isClient={isClient}
                                />
                            </>
                        )}
                    </div>
                </div>

                <BuyRespCategoryTiles itemSearch={buyPage} />

                {/* Follow Search */}
                <SharedFollowSearchBanner itemSearch={buyPage} user={user} />

                {isNlgContentEnabled && (
                    <SharedBottomModule
                        className={styles.bottomModule}
                        bottomModule={bottomModule}
                    />
                )}
                <BuySharedDescription buyPage={buyPage} />

                <BuySharedCMSFAQ buyPage={buyPage} />

                {/* Questions & Answers */}
                <SharedFAQ itemSearch={buyPage} />

                {isWordpressArticlesEnabled && (
                    <SharedWordpressArticles
                        itemSearch={buyPage}
                        headerClassName={styles.wpArticlesHeader}
                    />
                )}

                <SharedRespLinksBlock
                    item={null}
                    viewer={viewer}
                    itemSearch={buyPage}
                    links={internalBuyLinks}
                    popularSearchTermClassName={styles.popularSearchCategoryTerm}
                />
            </>
        </SharedSaveSearchContextProvider>
    );
};

const mapStateToProps = state => {
    const { filters, relayVariables } = state;
    const { generatedUriRef, page } = filters;
    const {
        variables: { isClient },
    } = relayVariables;

    return {
        page,
        generatedUriRef,
        isClient,
    };
};

BuyRespLayoutComponent.propTypes = {
    viewer: PropTypes.object.isRequired,
    relay: PropTypes.object.isRequired,
    isClient: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    generatedUriRef: PropTypes.string,
};

export const BuyRespLayout = createRefetchContainer(
    connect(mapStateToProps)(BuyRespLayoutComponent),
    {
        viewer: graphql`
            fragment BuyRespLayout_viewer on Viewer {
                isWordpressArticlesEnabled: isEnabledByLocale(featureName: "WP_EDITORIAL")
                isNlgContentEnabled: isEnabledByLocale(featureName: "NLG_CONTENT")
                isRelatedBuyPagesEnabled: isEnabledByLocale(featureName: "RELATED_BUY_PAGES")
                ...BuySharedRefetchContainer_viewer
                buyPage: itemSearch(
                    userId: $personalizationId
                    guestId: $guestId
                    uriRef: $uriRef
                    localeOrigin: $localeOrigin
                    first: $first
                    page: $page
                    buyPageId: $rootId
                    userCountryCode: $userCountryCode
                    regionsList: $regionsList
                    priceBookName: $priceBookName
                    regions: $regions
                ) {
                    totalResults
                    displayMaxNumberOfPages
                    displayUriRef
                    appliedFilters {
                        name
                        values {
                            displayName
                        }
                    }
                    meta {
                        buyPageAvailableItemCount
                    }
                    filters {
                        ...BuyRespRefineMenu_filters
                    }
                    bottomModule {
                        ...SharedBottomModule_bottomModule
                    }
                    ...BuySharedRefetchContainer_buyPage
                    ...BuySharedBreadcrumbs_buyPage
                    ...BuySharedTitle_buyPage
                    ...BuySharedRelatedPages_buyPage
                    ...BuySharedResultContainer_buyPage
                    ...SharedRecentSales_itemSearch
                    ...SharedYMAL_itemSearch @arguments(priceBookName: $priceBookName)
                    ...BuySharedDescription_buyPage
                    ...BuySharedHead_buyPage
                    ...BuySharedCMSFAQ_buyPage
                    ...SharedFAQ_itemSearch
                    ...SharedWordpressArticles_itemSearch
                    ...SharedRespLinksBlock_itemSearch
                    internalBuyLinks {
                        ...SharedRespLinksBlock_links
                    }
                    ...BuyRespTopNav_buyPage
                    ...BuyRespRefineMenu_itemSearch
                    ...SharedSaveSearchContext_itemSearch
                    ...SharedFollowTopButton_itemSearch
                    ...SharedFollowSearchBanner_itemSearch
                    ...BuyRespCategoryTiles_itemSearch
                    ...BuySharedPersonalizedSearchItems_itemSearch
                        @arguments(priceBookName: $priceBookName)
                }
                ...SharedRecentSales_viewer
                ...SharedRespLinksBlock_viewer
                ...BuySharedResultContainer_viewer
                ...BuyRespRefineMenu_viewer
                ...BuySharedPersonalizedSearchItems_viewer
                user(userId: $userId) @include(if: $fetchUser) {
                    ...BuyRespTopNav_user
                    ...BuyRespRefineMenu_user
                    ...BuySharedResultContainer_user
                    ...BuySharedPersonalizedSearchItems_user
                    ...SharedRecentSales_user
                    ...SharedYMAL_user
                    ...SharedSaveSearchContext_user
                    ...SharedFollowTopButton_user
                    ...SharedFollowSearchBanner_user
                }
            }
        `,
    },
    graphql`
        query BuyRespLayoutRefetchQuery(
            $uriRef: String!
            $localeOrigin: String!
            $isClient: Boolean!
            $isTrade: Boolean!
            $showSeller: Boolean!
            $first: Int!
            $page: Int!
            $rootId: String!
            $fetchBuy: Boolean!
            $userZipCode: String = ""
            $userCountryCode: String = ""
            $fetchRegionalInfo: Boolean = false
            $userId: String = ""
            $personalizationId: String = ""
            $guestId: String = ""
            $rerankUserId: String = ""
            $rerankGuestId: String = ""
            $fetchUser: Boolean!
            $skipUser: Boolean = true
            $followSearchTypes: [types] = [SEARCH, USER_PREFERENCE]
            $followSearchPages: [String]
            $priceBookName: String
            $regions: String = ""
            $regionsList: [String]
            $pageDisplayEnum: PageDisplayEnum = searchAndBrowse
        ) {
            viewer {
                isWordpressArticlesEnabled: isEnabledByLocale(featureName: "WP_EDITORIAL")
                ...BuySharedRefetchContainer_viewer
                buyPage: itemSearch(
                    userId: $personalizationId
                    guestId: $guestId
                    uriRef: $uriRef
                    localeOrigin: $localeOrigin
                    first: $first
                    page: $page
                    buyPageId: $rootId
                    userCountryCode: $userCountryCode
                    regionsList: $regionsList
                    priceBookName: $priceBookName
                    regions: $regions
                ) @include(if: $fetchBuy) {
                    totalResults
                    displayMaxNumberOfPages
                    displayUriRef
                    appliedFilters {
                        name
                        values {
                            displayName
                        }
                    }
                    meta {
                        buyPageAvailableItemCount
                    }
                    bottomModule {
                        ...SharedBottomModule_bottomModule
                    }
                    ...BuySharedRefetchContainer_buyPage
                    ...BuySharedBreadcrumbs_buyPage
                    ...BuySharedTitle_buyPage
                    ...BuySharedRelatedPages_buyPage
                    ...BuySharedResultContainer_buyPage
                    ...SharedRecentSales_itemSearch
                    ...SharedYMAL_itemSearch @arguments(priceBookName: $priceBookName)
                    ...BuySharedDescription_buyPage
                    ...BuySharedHead_buyPage
                    ...BuySharedCMSFAQ_buyPage
                    ...SharedFAQ_itemSearch
                    ...SharedWordpressArticles_itemSearch
                    ...SharedRespLinksBlock_itemSearch
                    internalBuyLinks {
                        ...SharedRespLinksBlock_links
                    }
                    ...BuyRespRefineMenu_itemSearch
                    ...BuyRespTopNav_buyPage
                    ...SharedSaveSearchContext_itemSearch
                    ...SharedFollowTopButton_itemSearch
                    ...BuyRespCategoryTiles_itemSearch
                    ...BuySharedPersonalizedSearchItems_itemSearch
                        @arguments(priceBookName: $priceBookName)
                }
                user(userId: $userId) @include(if: $fetchUser) @skip(if: $skipUser) {
                    ...BuyRespRefineMenu_user
                    ...BuyRespTopNav_user
                    ...BuySharedResultContainer_user
                    ...BuySharedPersonalizedSearchItems_user
                    ...SharedRecentSales_user
                    ...SharedYMAL_user
                    ...SharedSaveSearchContext_user
                    ...SharedFollowTopButton_user
                    ...SharedFollowSearchBanner_user
                }
                ...BuyRespRefineMenu_viewer
                ...BuySharedResultContainer_viewer
                ...SharedRecentSales_viewer
                ...BuySharedPersonalizedSearchItems_viewer
            }
        }
    `
);
